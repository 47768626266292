import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'


const BlockReviews = () => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
      <>

      <div className='mt-10 grid grid-cols-2 border border-solid rounded justify-center'>
        <div className='w-full justify-start'>
            <img src='https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fnextdoor.png?alt=media&token=52453f70-a4c9-4598-8356-eb0cb83b1d7d' alt=''/>
        </div>
        <div className='justify-end items-end w-[50%]'>
            <a href='https://nextdoor.com/pages/nova-mb-plumbing-falls-church-va/' target='_blank' rel='noopener noreferrer' className='block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800' >
                Write Reviews
            </a>
        </div>
      </div>
      <div className='grid grid-cols-1 gap-8 mt-8 xl:mt-12 lg:grid-cols-2 xl:grid-cols-3 m-12'>
        {
            rpdata?.works?.map((item, index) => {
                return(
                    <section className="">
                    <div key={index} className='p-8 border rounded-lg dark:border-gray-700'>
                      <p className="leading-loose text-black dark:text-black">
                        {item.description}
                      </p>
          
                      <div className="flex items-center mt-8 -mx-2">
                        <img
                          className="object-cover mx-2 rounded-full w-14 shrink-0 h-14 ring-4 ring-gray-300 dark:ring-gray-700"
                          src={item.gallery[0]}
                          alt=""
                        />         
                        <div className="mx-2">
                          <p className="font-semibold text-gray-800 dark:text-black">
                            {item.name}
                          </p>
                          <span class="text-sm text-gray-500 dark:text-gray-400">{item.subdescription}</span>
                        </div>
                      </div>
                    </div>
                  </section>
                )
            })
        }
    </div>
      </>
    );
}

export default BlockReviews